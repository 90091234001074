/* Front.css */

/* Default styles */
.container {
    flex-direction: row;
}

.sonal-photo {
    display: block;
}

.background-svg {
    display: block;
}

/* Mobile styles */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sonal-photo {
        align-self: flex-end;
    }

    .background-svg {
        display: none;
    }
    svg{
        display: none;
    }
}
