/* Basic styling for the navbar */
.navbar {
  position:fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: black;
    color: white;
   
  }
  
  /* Logo styling */
  .navbar-logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Styling for the navbar links */
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    transition: max-height 0.3s ease-in-out;
  }
  
  /* Styling for each navbar item */
  .navbar-item {
    margin-left: 20px;
  }
  
  /* Styling for each navbar link */
  .navbar-link {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  .navbar-link:hover{
    color:#08D665;
  }
  
  /* Menu toggle button styling */
  .navbar-toggle {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar-links {
      flex-direction: column;
      width: 100%;
      max-height: 0;
      overflow: hidden;
    }
  
    .navbar-links.active {
      max-height: 200px; /* Adjust according to your needs */
    }
  
    .navbar-item {
      margin: 10px 0;
    }
  
    .navbar-toggle {
      display: block;
    }
  }
  